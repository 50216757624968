import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import { Editor } from 'react-draft-wysiwyg';
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";


export default class SendEmail extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            subject: "",
            body:"",
            to:"",

            loading: false,
        };
        this.pathname = "email";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }

    }


    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, subject,editorState,to } = this.state;

        const body = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const data = {
            _id,
            subject,body,to
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            Utilites.showSucessMessage("Email Sent!")
        }
        this.setState({
            loading: false
        })
    };



    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };
    render() {
        // taking all the states
        const { loading, isLoading, subject,editorState,to } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Send Email</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label={"to"} name="to" data={to} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"subject"} name="subject" data={subject} changeInput={this.changeInput.bind(this)} />
                                        <Row>
                                            <Label sm="2">Description</Label>
                                            <Col sm="7" >
                                                <FormGroup   >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Send</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


